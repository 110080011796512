<template>
  <div>
    Loading...
  </div>
</template>
<script>
import { mapActions, mapMutations } from 'vuex';
import loginMixins from '@m/user/mixins/login';
import { setEnv } from '@/utils/env';
export default {
  mixins: [loginMixins],
  created() {
    try {
      const tokens = window.sessionStorage.getItem('user_auth_tokens');
      const appEnv = window.sessionStorage.getItem('app_env');
      const device_id = window.sessionStorage.getItem('device_id');
      const shifl_token = window.sessionStorage.getItem('shifl_auth_token');

      if(tokens && appEnv && device_id && shifl_token) {
        setEnv(appEnv);
        const tokenJson = JSON.parse(tokens);
        this.setUserAuthToken(tokenJson);
        this.setAuthToken(JSON.parse(shifl_token));
        this.$http.defaults.headers.common.AUTHORIZATION =
                'Bearer ' + tokenJson.accessToken;
        this.$http.defaults.headers.common['SD-Device-ID'] = device_id;

        this.verifyToken();
        window.sessionStorage.removeItem('user_auth_tokens');
        window.sessionStorage.removeItem('app_env');
        window.sessionStorage.removeItem('device_id');
        window.sessionStorage.removeItem('shifl_auth_token');
      } else {
        this.goToWelcomeScreen();
      }
    } catch {
      this.goToWelcomeScreen();
    }
  },
  methods: {
    ...mapActions('user', ['verifyOtp', 'getPerson', 'login']),
    ...mapMutations('user', ['setUserAuthToken','setAuthToken']),
    ...mapActions('kyc', ['submitKyc']),
    verifyToken() {
      const loader = this.showLoader();
      this.getPerson()
        .then((res) => {
          setTimeout(() => {
            this.startKYC(res);
          }, 0);
        })
        .catch((e) => {
          this.apiErrorMessage(e);
          console.log(e);
        })
        .finally(() => {
          loader.close();
        });
    },
    startKYC(person) {
      if (person && person.id) {
        const loader = this.showLoader();
        this.submitKyc(person.id)
          .then(() => {
            setTimeout(() => {
              this.loginSuccess(() => {
                this.$router.push('/kyc/kycStatus');
              });
            }, 0);
          })
          .catch((e) => {
            console.log(e);
            this.apiErrorMessage(e);
          })
          .finally(() => {
            loader.close();
          });
      }
    }
  }
}
</script>